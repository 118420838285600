import { createAction, props } from '@ngrx/store';
import type { HeatpumpsFunnelSettingsInterface } from '@innogy/eplus/models';

import type {
  MultipleAnswerQuestionOption,
  SingleAnswerQuestionOption,
} from './questions';

const PREFIX = '[Heatpumps Questionnaire]';

export const initializeHeatpumpsQuestionnaireAction = createAction(
  `${PREFIX} Initialize`
);

export const submitHeatpumpsQuestionnaireSingleAnswerAction = createAction(
  `${PREFIX} Submit single answer`,
  props<{ answer: SingleAnswerQuestionOption }>()
);

export const submitHeatpumpsQuestionnaireMultipleAnswerAction = createAction(
  `${PREFIX} Submit multiple answers`,
  props<{ answers: MultipleAnswerQuestionOption[] }>()
);

export const showHeatpumpsQuestionnaireNextQuestionAction = createAction(
  `${PREFIX} Show next question`,
  props<{ next: string }>()
);

export const showHeatpumpsQuestionnairePreviousQuestionAction = createAction(
  `${PREFIX} Show previous question`
);

export const openHeatpumpsQuestionnaireInfoModal = createAction(
  `${PREFIX} Open info modal`,
  props<{ funnelKey: keyof HeatpumpsFunnelSettingsInterface }>()
);
